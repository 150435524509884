export interface IGame {
    pk: string;
    sk: string;
    game_aggregated_rating?: number;
    game_external_id: string;
    game_slug: string;
    game_category: string;
    game_release_status: string;
    game_age_ratings: IAgeRestriction[];
    game_artworks?: string[];
    game_dlcs: IRelatedGame[];
    game_remakes: IRelatedGame[];
    game_remasters: IRelatedGame[];
    game_bundles: IRelatedGame[];
    game_bundled_games: IRelatedGame[];
    game_series: string;
    game_franchise: string;
    game_modes: [];
    game_player_perspectives: [];
    game_similar_games: IRelatedGame[];
    game_websites: IWebsites[];
    game_ports: IRelatedGame[];
    game_title: string;
    game_summary: string;
    game_storyline: string;
    game_release: string;
    game_cover_url: string;
    game_status: string;
    game_released_platforms: [];
    game_platform: string;
    game_platform_slug: string;
    game_platform_logo_url: string;
    game_genres: [];
    game_companies: IRelatedCompany[] | [];
    game_themes: [];
    game_screenshots?: string[];
    game_videos?: string[];
    game_favourite: boolean;
    game_retired: boolean;
    game_use_save_state: boolean;
    game_started_date?: string;
    game_completed_date?: string;
    game_duration: string;
    game_duration_minutes: string;
    game_rating: string;
    game_rating_visual: string;
    game_rating_audio: string;
    game_rating_gameplay: string;
    game_rating_story: string;
    game_rating_control: string;
    game_difficulty: string;
    game_remarks?: string,
    game_played_date?: string;
    game_progress?: string;
    game_remaining_complete?: string;
    game_backlog_date?: string;
    game_parent?: IRelatedGame;
    game_ownership?: string;
    game_format?: string;
    game_acquisition?: string;
    game_acquisition_date?: string;
    game_acquisition_source?: string;
    game_acquisition_state?: string;
    game_acquisition_completeness?: string;
    game_amount_paid?: string;
    game_condition?: string;
    game_box_condition?: string;
    game_manual_condition?: string;
    game_region?: string;
    game_progress_percentage: string;
    created_at: string;
    updated_at: string;
}

export interface IRelatedGame {
    game_slug: string;
    game_title: string;
    game_release?: string;
    game_cover_url?: string;
}

export interface IRelatedCompany {
    name: string;
    developer: boolean;
    publisher: boolean;
}

interface IAgeRestriction {
    category: string;
    rating: string;
    synopsis: string;
}

interface IWebsites {
    category: string;
    url: string;
}

export const EMPTY_GAME = {
    game_status: "",
    game_title: "",
    game_themes: [],
    game_companies: [],
    game_genres: [],
    game_released_platforms: [],
    game_platform: "",
    game_platform_logo_url: "",
    game_favourite: false,
    game_summary: "",
    game_storyline: "",
    game_release: "",
    game_cover_url: "",
    game_started_date: "",
    created_at: "",
    updated_at: "",
};