import { useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { setGameCollectionPageTitle, setReviewImageBackground } from "../../utils/Display";
import { EMPTY_REVIEW, IReview } from "../../types/Review";
import useAlert from "../../hooks/userAlert";
import GameService from "../../services/GameService";
import { BackgroundImageOutletContext } from "../../components/layout/MainLayout";
import ViewReview from "../../components/forms/ViewReview";

const ViewReviewPage = () => {
  const { id } = useParams();

  const { setAlert } = useAlert();
  const fecthErrorMessage = "We had a problem while fetching the game review";

  const [review, setReview] = useState(EMPTY_REVIEW as IReview);
  const [isLoading, setIsLoading] = useState(false);

  const { setBackgroundImage } =
    useOutletContext<BackgroundImageOutletContext>();

  useEffect(() => {
    setGameCollectionPageTitle(review.game_title);
    fetchGameReview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchGameReview = async () => {
    try {
      setIsLoading(true);
      const res = await GameService.getReviewByID(id);
      const reviewData = res.data;
      setReview(reviewData);
      setGameCollectionPageTitle(reviewData.game_title);
      setReviewImageBackground(reviewData, setBackgroundImage);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleResponseError(error, fecthErrorMessage);
    }
  };

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  return (
    <>
      {isLoading ? (
        <div className="spinner-grow" role="status" />
      ) : (
        <>
          <ViewReview review={review} gameId={id!} />
        </>
      )}
    </>
  );
};

export default ViewReviewPage;
