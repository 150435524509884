import { IGame } from "../../types/Game";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import GamepadOutlinedIcon from '@mui/icons-material/GamepadOutlined';
import FormatListNumberedOutlinedIcon from "@mui/icons-material/FormatListNumberedOutlined";
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import { dateYear, formatDate } from "../../utils/Formatter";
import { Tooltip } from "@mui/material";
import { getRatingLabel, getDifficultyLabel } from "../../utils/GamesUtils";

type Props = {
  game: IGame;
};

const GameCardDisplay = (props: Props) => {
  const gameDetails = (
    <>
      <div className="title mb-2">
        {props.game.game_title}
        &nbsp;
        <span className="release_date">
          ({dateYear(props.game.game_release)})
        </span>
      </div>

      {props.game.game_rating && (
        <div className="card-label d-flex align-items-center">
          <StarOutlineOutlinedIcon sx={{ marginRight: 0.5 }} fontSize="small" />
          <label>{getRatingLabel(props.game.game_rating)}</label>
        </div>
      )}

      {props.game.game_difficulty && (
        <div className="card-label  d-flex align-items-center">
          <SpeedOutlinedIcon sx={{ marginRight: 0.5 }} fontSize="small" />
          <label>{getDifficultyLabel(props.game.game_difficulty)}</label>
        </div>
      )}

      {props.game.game_completed_date && (
        <div className="card-label d-flex align-items-center">
          <EmojiEventsOutlinedIcon sx={{ marginRight: 0.5 }} fontSize="small" />
          <label>{formatDate(props.game.game_completed_date)}</label>
        </div>
      )}

      {props.game.game_played_date && (
        <div className="card-label d-flex align-items-center">
          <GamepadOutlinedIcon sx={{ marginRight: 0.5 }} fontSize="small" />
          <label>{formatDate(props.game.game_played_date)}</label>
        </div>
      )}

      {props.game.game_started_date && (
        <div className="card-label d-flex align-items-center">
          <OndemandVideoOutlinedIcon sx={{ marginRight: 0.5 }} fontSize="small" />
          <label>{formatDate(props.game.game_started_date)}</label>
        </div>
      )}

      {props.game.game_backlog_date && (
        <div className="card-label d-flex align-items-center">
          <FormatListNumberedOutlinedIcon
            sx={{ marginRight: 0.5 }}
            fontSize="small"
          />
          <label>{formatDate(props.game.game_backlog_date)}</label>
        </div>
      )}

      <div className="mb-2"></div>
    </>
  );

  return (
    <>
      <Tooltip title={gameDetails} placement="top" arrow>
        <div className="title mb-2">
          {props.game.game_title.length > 20
            ? props.game.game_title.slice(0, 20) + "..."
            : props.game.game_title}{" "}
          &nbsp;
          <span className="release_date">
            ({dateYear(props.game.game_release)})
          </span>
        </div>
      </Tooltip>

      {props.game.game_platform_logo_url && (
        <p>
          <img
            src={props.game.game_platform_logo_url}
            alt="platform logo"
            width="100"
          />
        </p>
      )}
    </>
  );
};

export default GameCardDisplay;
