import { Tooltip } from "@mui/material";
import GamepadOutlinedIcon from "@mui/icons-material/GamepadOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import FormatListNumberedOutlinedIcon from "@mui/icons-material/FormatListNumberedOutlined";

type Props = {
  status: string;
  favourite?: boolean;
  badgeSize?: "small" | "inherit" | "large" | "medium";
};

const GameBadges = (props: Props) => {
  const { status, favourite, badgeSize } = props;

  return (
    <span>
      {status === "playing" && (
        <Tooltip title="Now Playing" arrow>
          <OndemandVideoOutlinedIcon fontSize={badgeSize} />
        </Tooltip>
      )}
      {status === "completed" && (
        <Tooltip title="Completed" arrow>
          <EmojiEventsOutlinedIcon fontSize={badgeSize} />
        </Tooltip>
      )}
      {status === "played" && (
        <Tooltip title="Played" arrow>
          <GamepadOutlinedIcon fontSize={badgeSize} />
        </Tooltip>
      )}
      {status === "backlog" && (
        <Tooltip title="Backlog" arrow>
          <FormatListNumberedOutlinedIcon fontSize={badgeSize} />
        </Tooltip>
      )}
      {favourite && (
        <Tooltip title="Favourite" arrow>
          <FavoriteOutlinedIcon fontSize={badgeSize} />
        </Tooltip>
      )}
    </span>
  );
};

export default GameBadges;
