import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import UserMenu from "../menu/UserMenu";
import MenuIcon from "@mui/icons-material/Menu";
import DynamicFeedOutlinedIcon from "@mui/icons-material/DynamicFeedOutlined";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";

type Props = {
  handleDrawerToggle: () => void;
};

const Topbar = (props: Props) => {
  const menuIconSize = 35;
  const menuFontSize = 18;

  const pages = [
    {
      name: "FEED",
      path: "/main/feed",
      icon: <DynamicFeedOutlinedIcon sx={{ fontSize: menuIconSize }} />,
    },
    {
      name: "GAMES",
      path: "/main/games",
      icon: <SportsEsportsIcon sx={{ fontSize: menuIconSize }} />,
    },
    {
      name: "REVIEWS",
      path: "/main/reviews",
      icon: <ReviewsOutlinedIcon sx={{ fontSize: menuIconSize }} />,
    },
    {
      name: "LISTS",
      path: "/main/lists",
      icon: <FormatListBulletedOutlinedIcon sx={{ fontSize: menuIconSize }} />,
    },
    {
      name: "MEMBERS",
      path: "/main/members",
      icon: <PeopleAltOutlinedIcon sx={{ fontSize: menuIconSize }} />,
    },
  ];

  return (
    <AppBar
      position="fixed"
      sx={{
        width: {
          sm: `calc(100% - ${sizeConfigs.sidebar.width})`,
          md: `calc(100% - ${sizeConfigs.sidebar.width})`,
        },
        ml: {
          sm: `${sizeConfigs.sidebar.width}`,
          md: sizeConfigs.sidebar.width,
        },
        boxShadow: "unset",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={props.handleDrawerToggle}
          sx={{
            color: colorConfigs.sidebar.bg,
            mr: 2,
            display: { sm: "none" },
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
        />
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {pages.map((page) => (
            <Link to={page.path} style={{ textDecoration: "none" }}>
              <Button
                key={page.name}
                sx={{
                  mx: 1,
                  fontSize: menuFontSize,
                  ":hover": { color: colorConfigs.topbar.menuHoverColor },
                  color: colorConfigs.topbar.menuColor,
                  display: "block",
                }}
              >
                {page.icon} {page.name}
              </Button>
            </Link>
          ))}
        </Box>
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
