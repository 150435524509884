import { useNavigate, Link } from "react-router-dom";
import { Chip, Rating, Stack } from "@mui/material";
import { Button } from "react-bootstrap";
import {
  capitalize,
  dateYear,
  formatDateWithMonthName,
} from "../../utils/Formatter";
import ImageModal from "../modal/ImageModal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import MusicNoteOutlinedIcon from "@mui/icons-material/MusicNoteOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import GamepadOutlinedIcon from "@mui/icons-material/GamepadOutlined";
import { StarBorder } from "@mui/icons-material";
import { findGameDeveloper, getRatingLabel } from "../../utils/GamesUtils";
import { IReview } from "../../types/Review";
import { styled } from "@mui/system";
import StatRate from "../stats/StatRate";
import colorConfigs from "../../configs/colorConfigs";
import GameBadges from "../common/GameBadges";

type Props = {
  gameId: string;
  review: IReview;
};

const ViewReview = (props: Props) => {
  const { gameId } = props;
  const { review } = props;

  const STATS_HEIGHT = 90;
  const STATS_WIDTH = 90;

  const viewGameInfo = "/main/games/info/";
  const viewUserGame = "/main/games/view/" + encodeURI(gameId);

  const ratingEmptyColor = "#aeaeae";

  const pageTitle = `${review.game_title} review`;

  const navigate = useNavigate();

  const StyledChip = styled(Chip)({
    marginRight: 3,
  });

  const PointerChip = styled(StyledChip)({
    cursor: "pointer",
  });

  const closeForm = async (e: any) => {
    navigate(-1);
  };

  return (
    <>
      <div className="container">
        <div className="row mb-1">
          <h6 className="page-header">{pageTitle}</h6>
        </div>
        <section id="header" className="game-solid-background">
          <div className="container p-4">
            <div className="row">
              <div className="col-lg-9">
                <h5 className="game-title">
                  {formatDateWithMonthName(review.game_release)}
                </h5>
                <h4 className="game-title">
                  {findGameDeveloper(review.game_companies)}
                  &nbsp;
                  {review.game_category && (
                    <Chip
                      className="chip-outline-custom"
                      variant="outlined"
                      size="small"
                      key={review.game_category}
                      label={review.game_category}
                    />
                  )}
                </h4>
                {review.game_parent && (
                  <div className="card-label mb-2 d-flex align-items-center">
                    {review.game_category} of &nbsp;
                    <Link
                      className="link"
                      reloadDocument
                      to={`${viewGameInfo}${review.game_parent.game_slug}`}
                    >
                      {review.game_parent.game_title} (
                      {dateYear(review.game_parent.game_release)})
                    </Link>
                  </div>
                )}
                <div className="mt-3">
                  {review.game_released_platforms?.map((value) => (
                    <Link
                      className="link"
                      state={{ byPlatform: value }}
                      to={`/main/games/total`}
                    >
                      <PointerChip
                        className="chip-outline-custom"
                        variant="outlined"
                        size="medium"
                        key={value}
                        label={value}
                      />
                    </Link>
                  ))}
                </div>
                <div className="mt-3">
                  {review.game_genres
                    ?.concat(review.game_themes)
                    .map((value) => (
                      <Link
                        className="link"
                        state={{ byGenre: value }}
                        to={`/main/games/total`}
                      >
                        <PointerChip
                          className="chip-outline-custom"
                          variant="outlined"
                          size="small"
                          key={value}
                          label={value}
                        />
                      </Link>
                    ))}
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <h5 className="game-review-rating">Overall Rating</h5>
                      <Rating
                        readOnly
                        name="overall-ratings"
                        icon={<StarBorder fontSize="large" />}
                        emptyIcon={
                          <StarBorder
                            fontSize="large"
                            sx={{ color: ratingEmptyColor }}
                          />
                        }
                        max={10}
                        value={parseInt(review.game_rating)}
                      />
                      <div className="mb-2 review-rating">
                        {getRatingLabel(review.game_rating)}
                      </div>
                    </Stack>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <h5 className="game-review-rating">Visual</h5>
                      <StatRate
                        height={STATS_HEIGHT}
                        width={STATS_WIDTH}
                        rate={parseInt(review.rating_visual)}
                        isPercentage={false}
                        minValue={0}
                        maxValue={10}
                        rateFontSize={26}
                        trailColor={colorConfigs.stats.statRateTrail}
                        pathColor={colorConfigs.stats.statRatePath}
                        textColor={colorConfigs.stats.statRateText}
                        icon={
                          <DesktopWindowsOutlinedIcon
                            fontSize="small"
                            sx={{ color: "white" }}
                          />
                        }
                      />
                    </Stack>
                  </div>
                  <div className="col">
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <h5 className="game-review-rating">Audio</h5>
                      <StatRate
                        height={STATS_HEIGHT}
                        width={STATS_WIDTH}
                        rate={parseInt(review.rating_audio)}
                        isPercentage={false}
                        minValue={0}
                        maxValue={10}
                        rateFontSize={26}
                        trailColor={colorConfigs.stats.statRateTrail}
                        pathColor={colorConfigs.stats.statRatePath}
                        textColor={colorConfigs.stats.statRateText}
                        icon={
                          <MusicNoteOutlinedIcon
                            fontSize="small"
                            sx={{ color: "white" }}
                          />
                        }
                      />
                    </Stack>
                  </div>
                  <div className="col">
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <h5 className="game-review-rating">Controls</h5>
                      <StatRate
                        height={STATS_HEIGHT}
                        width={STATS_WIDTH}
                        rate={parseInt(review.rating_control)}
                        isPercentage={false}
                        minValue={0}
                        maxValue={10}
                        rateFontSize={26}
                        trailColor={colorConfigs.stats.statRateTrail}
                        pathColor={colorConfigs.stats.statRatePath}
                        textColor={colorConfigs.stats.statRateText}
                        icon={
                          <GamepadOutlinedIcon
                            fontSize="small"
                            sx={{ color: "white" }}
                          />
                        }
                      />
                    </Stack>
                  </div>
                  <div className="col">
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <h5 className="game-review-rating">Story</h5>
                      <StatRate
                        height={STATS_HEIGHT}
                        width={STATS_WIDTH}
                        rate={parseInt(review.rating_story)}
                        isPercentage={false}
                        minValue={0}
                        maxValue={10}
                        rateFontSize={26}
                        trailColor={colorConfigs.stats.statRateTrail}
                        pathColor={colorConfigs.stats.statRatePath}
                        textColor={colorConfigs.stats.statRateText}
                        icon={
                          <AutoStoriesOutlinedIcon
                            fontSize="small"
                            sx={{ color: "white" }}
                          />
                        }
                      />
                    </Stack>
                  </div>
                  <div className="col">
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <h5 className="game-review-rating">Gameplay</h5>
                      <StatRate
                        height={STATS_HEIGHT}
                        width={STATS_WIDTH}
                        rate={parseInt(review.rating_gameplay)}
                        isPercentage={false}
                        minValue={0}
                        maxValue={10}
                        rateFontSize={26}
                        trailColor={colorConfigs.stats.statRateTrail}
                        pathColor={colorConfigs.stats.statRatePath}
                        textColor={colorConfigs.stats.statRateText}
                        icon={
                          <SportsEsportsOutlinedIcon
                            fontSize="small"
                            sx={{ color: "white" }}
                          />
                        }
                      />
                    </Stack>
                  </div>
                </div>
              </div>
              <div className="col col-lg-3">
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ImageModal imageURL={review.game_cover_url} />
                  <div className="row justify-content-center mt-1">
                    <Link
                      className="link-button"
                      to={`${viewGameInfo}${review.game_slug}`}
                    >
                      <Button
                        className="btn btn-sm button-outline-custom-invert m-1"
                        style={{ width: 200 }}
                      >
                        <InfoOutlinedIcon /> VIEW GAME INFO
                      </Button>
                    </Link>
                    <Link className="link-button" to={viewUserGame}>
                      <Button
                        className="btn btn-sm button-outline-custom m-1"
                        style={{ width: 200 }}
                      >
                        <SportsEsportsOutlinedIcon /> GAME IN COLLECTION
                      </Button>
                    </Link>
                  </div>
                </Stack>
              </div>
            </div>
          </div>
        </section>
        <section id="review">
          <div className="container p-4 mt-4">
            <div className="row">
              <h3 className="game-title display-6 fw-bold text-body-emphasis">
                {review.review_title}
              </h3>
            </div>
            <div className="row">
              <span>
                <GameBadges status={review.game_status} />{" "}
                {capitalize(review.game_status)} by {review.reviewer} on{" "}
                {review.game_platform} in {review.game_duration} hour(s)
              </span>
            </div>
            <div className="row">
              <div>
                Reviewed on {formatDateWithMonthName(review.review_date)}
              </div>
            </div>
            <div className="row mt-5">
              <div className="game-review-text">
                {review.review_description}
              </div>
            </div>
          </div>
        </section>

        <form>
          <div className="container mt-5">
            <div className="row align-items-center">
              <Button
                className="button-outline-custom"
                onClick={(e) => closeForm(e)}
              >
                CLOSE
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ViewReview;
