import { useEffect, useState } from "react";
import { setReviewsPageTitle, setThemeBackground } from "../../utils/Display";
import { useLocation, useOutletContext } from "react-router-dom";
import { BackgroundImageOutletContext } from "../layout/MainLayout";
import GameService from "../../services/GameService";
import useAlert from "../../hooks/userAlert";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import { IOption } from "../../types/Option";
import { Pagination, Tooltip } from "@mui/material";
import { Button } from "react-bootstrap";
import SelectOptions from "../../data/SelectOptions";
import { IReview } from "../../types/Review";
import ReviewCard from "../card/ReviewCard";

const GamesReviews = () => {
  const fecthErrorMessage = "We had a problem while fetching games reviews";
  const errorSelectOptions = "We had a problem while fetching the options data";

  const { setAlert } = useAlert();

  const { setBackgroundImage } =
    useOutletContext<BackgroundImageOutletContext>();

  const [filterResultsTerm, setFilterResultsTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState([] as IReview[]);

  const [totalPages, setTotalPages] = useState(0);
  const [resultCount, setResultCount] = useState(0);

  const location = useLocation();
  let { state } = location;

  if (!state) {
    state = {
      byPlatform: "",
      byGenre: "",
      byTheme: "",
      byRating: "",
      page: 1,
      pageSize: 30,
      sortField: "review_date",
      sortOrder: "desc",
    };
  }

  // Games filters state
  const [filters, setFilters] = useState({
    byPlatform: state.byPlatform,
    byGenre: state.byGenre,
    byTheme: state.byTheme,
    byRating: state.byRating,
    page: state.page,
    pageSize: state.pageSize,
    sortField: state.sortField,
    sortOrder: state.sortOrder,
  });

  const [platforms, setPlatforms] = useState([] as IOption[]);
  const [genres, setGenres] = useState([] as IOption[]);
  const [themes, setThemes] = useState([] as IOption[]);

  // const filteredGames = useMemo(() => {
  //   return gamesUtils.filterGames(filterResultsTerm, games);
  // }, [filterResultsTerm, games]);

  useEffect(() => {
    setThemeBackground(setBackgroundImage);
    setReviewsPageTitle();
    fetchReviews();
    fetchPlatforms();
    fetchGenres();
    fetchThemes();
    // eslint-disable-next-line
  }, [filters]);

  const clearFilters = async (e: any) => {
    setFilters({
      byPlatform: "",
      byGenre: "",
      byTheme: "",
      byRating: "",
      page: 1,
      pageSize: 30,
      sortField: "review_date",
      sortOrder: "desc",
    });
  };

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  const fetchPlatforms = async () => {
    try {
      const res = await GameService.getPlatforms();
      setPlatforms(res.data);
    } catch (error) {
      handleResponseError(error, errorSelectOptions);
    }
  };

  const fetchGenres = async () => {
    try {
      const res = await GameService.getGenres();
      setGenres(res.data);
    } catch (error) {
      handleResponseError(error, errorSelectOptions);
    }
  };

  const fetchThemes = async () => {
    try {
      const res = await GameService.getThemes();
      setThemes(res.data);
    } catch (error) {
      handleResponseError(error, errorSelectOptions);
    }
  };

  const onChangeFilter = (e: any) => {
    setFilters({ ...filters, [e.target.name]: e.target.value, page: 1 });
  };

  const fetchReviews = async () => {
    try {
      setIsLoading(true);
      const response = await GameService.getAllReviews(filters);
      const result = response.data;
      setReviews(result.reviews);
      setTotalPages(result.totalPages);
      setResultCount(result.resultCount);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      setAlert(fecthErrorMessage, "error");
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setFilters({ ...filters, page: value });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <h6 className="page-header">Reviews</h6>
      </div>

      <section id="header" className="game-solid-background">
        <div className="p-4">          
          <div className="row">
            <div className="col-sm-12 col-md-6 my-2">
              <div className="input-group-sm">
                <div className="mb-2 d-flex align-items-center">
                  <ManageSearchOutlinedIcon sx={{ marginRight: 0.5 }} />
                  <label>Platform</label>
                </div>
                <select
                  className="form-select"
                  value={filters.byPlatform}
                  name="byPlatform"
                  onChange={onChangeFilter}
                >
                  <option key="" value="" />
                  {platforms.map((name) => {
                    return (
                      <option key={name.value} value={name.value}>
                        {name.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 my-2">
              <div className="input-group-sm">
                <div className="mb-2 d-flex align-items-center">
                  <ManageSearchOutlinedIcon sx={{ marginRight: 0.5 }} />
                  <label>Genre</label>
                </div>
                <select
                  className="form-select"
                  value={filters.byGenre}
                  name="byGenre"
                  onChange={onChangeFilter}
                >
                  <option key="" value="" />
                  {genres.map((name) => {
                    return (
                      <option key={name.value} value={name.value}>
                        {name.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 my-2">
              <div className="input-group-sm">
                <div className="mb-2 d-flex align-items-center">
                  <ManageSearchOutlinedIcon sx={{ marginRight: 0.5 }} />
                  <label>Theme</label>
                </div>
                <select
                  className="form-select"
                  value={filters.byTheme}
                  name="byTheme"
                  onChange={onChangeFilter}
                >
                  <option key="" value="" />
                  {themes.map((name) => {
                    return (
                      <option key={name.value} value={name.value}>
                        {name.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 my-2">
              <div className="input-group-sm">
                <div className="mb-2 d-flex align-items-center">
                  <ManageSearchOutlinedIcon sx={{ marginRight: 0.5 }} />
                  <label>Rating</label>
                </div>
                <select
                  className="form-select"
                  value={filters.byRating}
                  name="byRating"
                  onChange={onChangeFilter}
                >
                  <option key="" value="" />
                  {SelectOptions.ratings.map((item: IOption) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-sm-12 col-md-1">
              <div>
                <Tooltip title="Clear Filters" arrow>
                  <Button
                    className="button-outline-custom"
                    size="sm"
                    onClick={clearFilters}
                  >
                    <FilterAltOffOutlinedIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </section>

      {isLoading ? (
        <div className="spinner-grow mt-4" role="status" />
      ) : reviews.length > 0 ? (
        <>
          <div className="row mt-3">
            <h3 className="games-count">{resultCount} Reviews</h3>
          </div>
          <div className="row d-flex align-items-center mb-3">
            <div className="col-sm-12 col-md-8 input-group-sm">
              <input
                type="text"
                className="form-control"
                placeholder="Find in results"
                id="input-with-sx"
                value={filterResultsTerm}
                onChange={(e) => setFilterResultsTerm(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-2 input-group-sm">
              <select
                className="form-select"
                value={filters.sortField}
                name="sortField"
                onChange={onChangeFilter}
              >
                {SelectOptions.reviewsSortField.map((option) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-sm-12 col-md-2 input-group-sm">
              <select
                className="form-select"
                value={filters.sortOrder}
                name="sortOrder"
                onChange={onChangeFilter}
              >
                {SelectOptions.gamesSortOrder.map((option) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="row no-gutters">
            <div className="table-responsive">
              <table className="table table-hover table-dark table-striped">
                <tbody>
                  {reviews.map((review, index) => (
                    <tr>
                      <ReviewCard review={review} />
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="mt-4">
            <Pagination
              showFirstButton
              showLastButton
              shape="rounded"
              color="secondary"
              size="large"
              count={totalPages}
              page={filters.page}
              onChange={handlePageChange}
            />
          </div>
        </>
      ) : (
        <div className="mt-4">
          <h6>No results found based on your current search.</h6>
          <p>Please adjust your search criteria and give it another go.</p>
        </div>
      )}
    </div>
  );
};

export default GamesReviews;
