import GameCard from "../card/GameCard";
import SearchCardActions from "../card/SearchCardActions";
import { useEffect, useMemo, useState } from "react";
import gamesUtils from "../../utils/GamesUtils";
import SearchCardDisplay from "../card/SearchCardDisplay";
import { setGamesPageTitle, setThemeBackground } from "../../utils/Display";
import { useLocation, useOutletContext } from "react-router-dom";
import { BackgroundImageOutletContext } from "../layout/MainLayout";
import GameService from "../../services/GameService";
import { IGame } from "../../types/Game";
import useAlert from "../../hooks/userAlert";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { IOption } from "../../types/Option";
import { Pagination, Tooltip } from "@mui/material";
import { Button } from "react-bootstrap";
import SelectOptions from "../../data/SelectOptions";

const GameSearch = () => {
  const fecthErrorMessage =
    "We had a problem while fetching the search results";
  const errorSelectOptions = "We had a problem while fetching the options data";

  const { setAlert } = useAlert();

  const { setBackgroundImage } =
    useOutletContext<BackgroundImageOutletContext>();

  const [filterResultsTerm, setFilterResultsTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [games, setGames] = useState([] as IGame[]);
  const [searchTermButton, setSearchTermButton] = useState("");

  const [totalPages, setTotalPages] = useState(0);
  const [gameCount, setGameCount] = useState(0);


  const location = useLocation();
  let { state } = location;

  if (!state) {
    state = {
      searchTerm: "",
      byPlatform: "",
      byGenre: "",
      byTheme: "",
      byCategory: "",
      byMode: "",
      byPerspective: "",
      page: 1,
      pageSize: 30,
      sortField: "aggregated_rating",
      sortOrder: "desc",
    };
  }

  // Games filters state
  const [filters, setFilters] = useState({
    searchTerm: state.searchTerm,
    byPlatform: state.byPlatform,
    byGenre: state.byGenre,
    byTheme: state.byTheme,
    byCategory: state.byCategory,
    byMode: state.byMode,
    byPerspective: state.byPerspective,
    page: state.page,
    pageSize: state.pageSize,
    sortField: state.sortField,
    sortOrder: state.sortOrder,
  });

  const [platforms, setPlatforms] = useState([] as IOption[]);
  const [genres, setGenres] = useState([] as IOption[]);
  const [themes, setThemes] = useState([] as IOption[]);
  const [categories, setCategories] = useState([] as IOption[]);
  const [modes, setModes] = useState([] as IOption[]);
  const [perspectives, setPerspectives] = useState([] as IOption[]);

  const filteredGames = useMemo(() => {
    return gamesUtils.filterGames(filterResultsTerm, games);
  }, [filterResultsTerm, games]);

  useEffect(() => {
    setThemeBackground(setBackgroundImage);
    setGamesPageTitle();
    performSearch();
    fetchPlatforms();
    fetchGenres();
    fetchThemes();
    fetchCategories();
    fetchModes();
    fetchPerspectives();
    // eslint-disable-next-line
  }, [filters]);

  const clearFilters = async (e: any) => {
    setFilters({
      searchTerm: "",
      byPlatform: "",
      byGenre: "",
      byTheme: "",
      byCategory: "",
      byMode: "",
      byPerspective: "",
      page: 1,
      pageSize: 30,
      sortField: "aggregated_rating",
      sortOrder: "desc",
    });
  };

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  const fetchPlatforms = async () => {
    try {
      const res = await GameService.getPlatformsGameSearch();
      setPlatforms(res.data);
    } catch (error) {
      handleResponseError(error, errorSelectOptions);
    }
  };

  const fetchGenres = async () => {
    try {
      const res = await GameService.getGenresGameSearch();
      setGenres(res.data);
    } catch (error) {
      handleResponseError(error, errorSelectOptions);
    }
  };

  const fetchThemes = async () => {
    try {
      const res = await GameService.getThemesGameSearch();
      setThemes(res.data);
    } catch (error) {
      handleResponseError(error, errorSelectOptions);
    }
  };

  const fetchCategories = async () => {
    try {
      const res = await GameService.getGameCategoriesGameSearch();
      setCategories(res.data);
    } catch (error) {
      handleResponseError(error, errorSelectOptions);
    }
  };

  const fetchModes = async () => {
    try {
      const res = await GameService.getGameModesGameSearch();
      setModes(res.data);
    } catch (error) {
      handleResponseError(error, errorSelectOptions);
    }
  };

  const fetchPerspectives = async () => {
    try {
      const res = await GameService.getPlayerPerspectivesGameSearch();
      setPerspectives(res.data);
    } catch (error) {
      handleResponseError(error, errorSelectOptions);
    }
  };

  const onKeydown = (e: any) => {
    if (e.key === "Enter") {
      setFilters({ ...filters, [e.target.name]: e.target.value });
    }
  };

  const onChangeFilter = (e: any) => {
    setFilters({ ...filters, [e.target.name]: e.target.value, page: 1 });
  };

  const onChangeSearchTerm = (e: any) => {
    setSearchTermButton(e.target.value)
  };

  const onSearchButtonClick = (e: any) => {
    setFilters({ ...filters, searchTerm: searchTermButton, page: 1 });
  };

  const performSearch = async () => {
    try {
      setIsLoading(true);
      const response = await GameService.searchGames(filters);
      const result = response.data;
      setGames(result.games);
      setTotalPages(result.totalPages);
      setGameCount(result.gameCount);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      setAlert(fecthErrorMessage, "error");
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setFilters({ ...filters, page: value });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <h6 className="page-header">Games</h6>
      </div>

      <section id="header" className="game-solid-background">
        <div className="p-4">
          <div className="row d-flex align-items-center">
            <div className="col-sm-12 col-md-11">
              <div className="input-group">
                <input
                  type="text"
                  name="searchTerm"
                  className="form-control"
                  placeholder="Start a new game search..."
                  onKeyDown={onKeydown}
                  onChange={onChangeSearchTerm}
                />
                <button
                  className="btn-sm btn-outline"
                  type="button"
                  id="button-search"
                  onClick={onSearchButtonClick}
                >
                  <SearchOutlinedIcon fontSize="small" />
                </button>
              </div>
            </div>
            <div className="col-sm-12 col-md-1">
              <div>
                <Tooltip title="Clear Filters" arrow>
                  <Button
                    className="button-outline-custom"
                    size="sm"
                    onClick={clearFilters}
                  >
                    <FilterAltOffOutlinedIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-4 my-2">
              <div className="input-group-sm">
                <div className="mb-2 d-flex align-items-center">
                  <ManageSearchOutlinedIcon sx={{ marginRight: 0.5 }} />
                  <label>Game Category</label>
                </div>
                <select
                  className="form-select"
                  value={filters.byCategory}
                  name="byCategory"
                  onChange={onChangeFilter}
                >
                  <option key="" value="" />
                  {categories.map((name) => {
                    return (
                      <option key={name.value} value={name.value}>
                        {name.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 my-2">
              <div className="input-group-sm">
                <div className="mb-2 d-flex align-items-center">
                  <ManageSearchOutlinedIcon sx={{ marginRight: 0.5 }} />
                  <label>Platform</label>
                </div>
                <select
                  className="form-select"
                  value={filters.byPlatform}
                  name="byPlatform"
                  onChange={onChangeFilter}
                >
                  <option key="" value="" />
                  {platforms.map((name) => {
                    return (
                      <option key={name.value} value={name.value}>
                        {name.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 my-2">
              <div className="input-group-sm">
                <div className="mb-2 d-flex align-items-center">
                  <ManageSearchOutlinedIcon sx={{ marginRight: 0.5 }} />
                  <label>Genre</label>
                </div>
                <select
                  className="form-select"
                  value={filters.byGenre}
                  name="byGenre"
                  onChange={onChangeFilter}
                >
                  <option key="" value="" />
                  {genres.map((name) => {
                    return (
                      <option key={name.value} value={name.value}>
                        {name.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 my-2">
              <div className="input-group-sm">
                <div className="mb-2 d-flex align-items-center">
                  <ManageSearchOutlinedIcon sx={{ marginRight: 0.5 }} />
                  <label>Theme</label>
                </div>
                <select
                  className="form-select"
                  value={filters.byTheme}
                  name="byTheme"
                  onChange={onChangeFilter}
                >
                  <option key="" value="" />
                  {themes.map((name) => {
                    return (
                      <option key={name.value} value={name.value}>
                        {name.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 my-2">
              <div className="input-group-sm">
                <div className="mb-2 d-flex align-items-center">
                  <ManageSearchOutlinedIcon sx={{ marginRight: 0.5 }} />
                  <label>Game Mode</label>
                </div>
                <select
                  className="form-select"
                  value={filters.byMode}
                  name="byMode"
                  onChange={onChangeFilter}
                >
                  <option key="" value="" />
                  {modes.map((name) => {
                    return (
                      <option key={name.value} value={name.value}>
                        {name.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 my-2">
              <div className="input-group-sm">
                <div className="mb-2 d-flex align-items-center">
                  <ManageSearchOutlinedIcon sx={{ marginRight: 0.5 }} />
                  <label>Player Perspective</label>
                </div>
                <select
                  className="form-select"
                  value={filters.byPerspective}
                  name="byPerspective"
                  onChange={onChangeFilter}
                >
                  <option key="" value="" />
                  {perspectives.map((name) => {
                    return (
                      <option key={name.value} value={name.value}>
                        {name.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
      </section>

      {isLoading ? (
        <div className="spinner-grow mt-4" role="status" />
      ) : games.length > 0 ? (
        <>
          <div className="row mt-3">
            <h3 className="games-count">{gameCount} Games</h3>
          </div>
          <div className="row d-flex align-items-center mb-3">
            <div className="col-sm-12 col-md-8 input-group-sm">
              <input
                type="text"
                className="form-control"
                placeholder="Find in search results"
                id="input-with-sx"
                value={filterResultsTerm}
                onChange={(e) => setFilterResultsTerm(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-2 input-group-sm">
              <select
                className="form-select"
                value={filters.sortField}
                name="sortField"
                onChange={onChangeFilter}
              >
                {SelectOptions.gamesSortField.map((option) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-sm-12 col-md-2 input-group-sm">
              <select
                className="form-select"
                value={filters.sortOrder}
                name="sortOrder"
                onChange={onChangeFilter}
              >
                {SelectOptions.gamesSortOrder.map((option) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="row no-gutters">
            {filteredGames.map((game, index) => (
              <div
                key={index}
                className="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-2"
              >
                <GameCard
                  game={game}
                  display={<SearchCardDisplay game={game} />}
                  action={<SearchCardActions game={game} />}
                />
              </div>
            ))}
          </div>

          <div className="mt-4">
            <Pagination
              showFirstButton
              showLastButton
              shape="rounded"
              color="secondary"
              size="large"
              count={totalPages}
              page={filters.page}
              onChange={handlePageChange}
            />
          </div>
        </>
      ) : (
        <div className="mt-4">
          <h6>No game results found based on your current search.</h6>
          <p>Please adjust your search criteria and give it another go.</p>
        </div>
      )}
    </div>
  );
};

export default GameSearch;
