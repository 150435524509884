import Counter from "../../components/counter/Counter";
import { useEffect, useState } from "react";
import GameService from "../../services/GameService";
import { IStats } from "../../types/Stats";
import useAlert from "../../hooks/userAlert";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import FormatListNumberedOutlinedIcon from "@mui/icons-material/FormatListNumberedOutlined";
import VideogameAssetOutlinedIcon from "@mui/icons-material/VideogameAssetOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import FavoriteIcon from "@mui/icons-material/Favorite";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import GamepadOutlinedIcon from "@mui/icons-material/GamepadOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import SentimentVerySatisfiedOutlinedIcon from "@mui/icons-material/SentimentVerySatisfiedOutlined";
import { StarBorder } from "@mui/icons-material";
import StatRate from "../../components/stats/StatRate";
import { Link, useOutletContext } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import sessionManager from "../../utils/SessionManager";
import {
  convertHoursToYearsMonthsEtc,
} from "../../utils/Formatter";
import { Rating, Tooltip } from "@mui/material";
import { BackgroundImageOutletContext } from "../../components/layout/MainLayout";
import { setMainPageTitle, setThemeBackground } from "../../utils/Display";
import { getDifficultyLabel, getRatingLabel } from "../../utils/GamesUtils";

type Props = {};

const HomePage = (props: Props) => {
  const ratingEmptyColor = "#aeaeae";
  const noGamingData = "No current gaming data to be displayed.";

  const { setBackgroundImage } =
    useOutletContext<BackgroundImageOutletContext>();

  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState({} as IStats);

  const { setAlert } = useAlert();
  const fecthErrorMessage = "We had a problem while fetching gaming stats";

  const totalHoursTooTip = (
    <>
      <p>Which is about:</p>
      <ul>
        {convertHoursToYearsMonthsEtc(stats.total_hours)?.map((item) => {
          return <li>{item}</li>;
        })}
      </ul>
      <p>
        ... of non-stop gameplay! <SentimentVerySatisfiedOutlinedIcon />
      </p>
    </>
  );

  useEffect(() => {
    setThemeBackground(setBackgroundImage);
    setMainPageTitle();
    fetchGamingStats();
    // eslint-disable-next-line
  }, []);

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  const fetchGamingStats = async () => {
    try {
      setIsLoading(true);
      const response = await GameService.getGamingStats();
      setStats(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleResponseError(error, fecthErrorMessage);
    }
  };

  const RenderGameStats = () => {
    return (
      <>
        <h2 className="user-header">
          <span className="filtered-value">
            {sessionManager.getFormattedUserName()}
          </span>{" "}
          Gaming Journey
        </h2>
        <p className="mb-2" />
        <div className="row">
          <div className="col-sm-12 col-md-4 col-lg-2 mb-4">
            <Counter
              link="total"
              count={stats.total}
              countSize="40px"
              text="Games"
              textSize="16px"
              icon={<SportsEsportsIcon />}
              class="game-counter fw-bold rounded"
            />
          </div>
          <div className="col-sm-12 col-md-4 col-lg-2 mb-4">
            <Counter
              link="completed"
              count={stats.completed}
              countSize="40px"
              text="Completed"
              textSize="16px"
              icon={<EmojiEventsOutlinedIcon />}
              class="game-counter fw-bold rounded"
            />
          </div>
          <div className="col-sm-12 col-md-4 col-lg-2 mb-4">
            <Counter
              link="playing"
              count={stats.playing}
              countSize="40px"
              text="Playing"
              textSize="16px"
              icon={<OndemandVideoOutlinedIcon />}
              class="game-counter fw-bold rounded"
            />
          </div>
          <div className="col-sm-12 col-md-4 col-lg-2 mb-4">
            <Counter
              link="played"
              count={stats.played}
              countSize="40px"
              text="Played/Retired"
              textSize="16px"
              icon={<GamepadOutlinedIcon />}
              class="game-counter fw-bold rounded"
            />
          </div>
          <div className="col-sm-12 col-md-4 col-lg-2 mb-4">
            <Counter
              link="backlog"
              count={stats.backlog}
              countSize="40px"
              text="Backlog"
              textSize="16px"
              icon={<FormatListNumberedOutlinedIcon />}
              class="game-counter fw-bold rounded"
            />
          </div>
          <div className="col-sm-12 col-md-4 col-lg-2 mb-4">
            <Counter
              link="favourites"
              count={stats.favourites}
              countSize="40px"
              text="Favourites"
              textSize="16px"
              icon={<FavoriteIcon />}
              class="game-counter fw-bold rounded"
            />
          </div>
          <p className="mb-2" />
          <Tooltip title={totalHoursTooTip} arrow placement="bottom">
            <div className="col-sm-12 col-md-6 col-lg-3 mb-4 d-flex justify-content-center">
              <StatRate
                height={180}
                width={180}
                rate={stats.total_hours}
                isPercentage={false}
                minValue={0}
                maxValue={0}
                rateFontSize={30}
                trailColor={colorConfigs.stats.statRateTrail}
                pathColor={colorConfigs.stats.statRatePath}
                textColor={colorConfigs.stats.statRateText}
                icon={
                  <TimerOutlinedIcon
                    sx={{ color: colorConfigs.stats.statRateText }}
                    fontSize="large"
                  />
                }
                text="Total Hours"
              />
            </div>
          </Tooltip>
          <div className="col-sm-12 col-md-6 col-lg-3 mb-4 d-flex justify-content-center">
            <StatRate
              height={180}
              width={180}
              rate={stats.completion_rate}
              isPercentage={true}
              minValue={0}
              maxValue={100}
              rateFontSize={30}
              trailColor={colorConfigs.stats.statRateTrail}
              pathColor={colorConfigs.stats.statRatePath}
              textColor={colorConfigs.stats.statRateText}
              icon={
                <EmojiEventsOutlinedIcon
                  sx={{ color: colorConfigs.stats.statRateText }}
                  fontSize="large"
                />
              }
              text="Completion Ratio"
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 mb-4 d-flex justify-content-center">
            <StatRate
              height={180}
              width={180}
              rate={stats.played_rate}
              isPercentage={true}
              minValue={0}
              maxValue={100}
              rateFontSize={30}
              trailColor={colorConfigs.stats.statRateTrail}
              pathColor={colorConfigs.stats.statRatePath}
              textColor={colorConfigs.stats.statRateText}
              icon={
                <GamepadOutlinedIcon
                  sx={{ color: colorConfigs.stats.statRateText }}
                  fontSize="large"
                />
              }
              text="Played Ratio"
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 mb-2 d-flex justify-content-center">
            <StatRate
              height={180}
              width={180}
              rate={stats.backlog_rate}
              isPercentage={true}
              minValue={0}
              maxValue={100}
              rateFontSize={30}
              trailColor={colorConfigs.stats.statRateTrail}
              pathColor={colorConfigs.stats.statRatePath}
              textColor={colorConfigs.stats.statRateText}
              icon={
                <FormatListNumberedOutlinedIcon
                  sx={{ color: colorConfigs.stats.statRateText }}
                  fontSize="large"
                />
              }
              text="Backlog Ratio"
            />
          </div>
        </div>

        <div>
          <p className="mb-2" />
          <h3 className="page-title">Completed Games</h3>
          <p className="mb-3" />
          <div className="container-fluid">
            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <TimerOutlinedIcon fontSize="large" /> Longest Gameplays
                  </h5>
                  {stats.top_10_longest_gameplays?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Game</th>
                          <th scope="col">Title</th>
                          <th className="right" scope="col">
                            Hours
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.top_10_longest_gameplays?.map((item) => (
                          <tr>
                            <td>
                              {
                                <img
                                  src={item.cover}
                                  alt="game cover"
                                  width="40"
                                />
                              }
                            </td>
                            <td className="align-middle">
                              <Link
                                className="link"
                                to={`/main/games/view/${item.id}`}
                              >
                                {" "}
                                {item.title}
                              </Link>
                            </td>
                            <td className="right align-middle">{item.value}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <VideogameAssetOutlinedIcon fontSize="large" /> Top
                    Platforms
                  </h5>
                  {stats.completed_by_platforms?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Platform</th>
                          <th scope="col">Name</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.completed_by_platforms
                          ?.slice(0, 10)
                          .map((item) => (
                            <tr>
                              <td>
                                {
                                  <img
                                    src={item.logo}
                                    alt="platform"
                                    width="54"
                                  />
                                }
                              </td>
                              <td className="align-middle">
                                <Link
                                  className="link"
                                  state={{
                                    byPlatform: item.name,
                                    byPlatformLogo: item.logo,
                                  }}
                                  to={`/main/games/completed`}
                                >
                                  {item.name}
                                </Link>
                              </td>
                              <td className="right align-middle">
                                {item.count}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <ComputerOutlinedIcon fontSize="large" /> Top Developers
                  </h5>
                  {stats.completed_by_developers?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Company</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.completed_by_developers
                          ?.slice(0, 10)
                          .map((item) => (
                            <tr>
                              <td>
                                <Link
                                  className="link"
                                  state={{ byCompany: item.company }}
                                  to={`/main/games/completed`}
                                >
                                  {item.company.name}
                                </Link>
                              </td>
                              <td className="right">{item.count}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <BusinessIcon fontSize="large" /> Top Publishers
                  </h5>
                  {stats.completed_by_publishers?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Company</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.completed_by_publishers
                          ?.slice(0, 10)
                          .map((item) => (
                            <tr>
                              <td>
                                <Link
                                  className="link"
                                  state={{ byCompany: item.company }}
                                  to={`/main/games/completed`}
                                >
                                  {item.company.name}
                                </Link>
                              </td>
                              <td className="right">{item.count}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <StarOutlineOutlinedIcon fontSize="large" /> By Ratings
                  </h5>
                  {stats.completed_by_ratings?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Rating</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.completed_by_ratings?.map((item) => (
                          <tr>
                            <td className="align-middle">
                              <Link
                                className="link"
                                state={{ byRating: item.name }}
                                to={`/main/games/completed`}
                              >
                                <Rating
                                  name="overall-ratings"
                                  disabled={true}
                                  emptyIcon={
                                    <StarBorder
                                      sx={{ color: ratingEmptyColor }}
                                    />
                                  }
                                  max={10}
                                  value={parseInt(item.name)}
                                />
                                <div className="rating">
                                  {getRatingLabel(item.name)}
                                </div>
                              </Link>
                            </td>
                            <td className="right align-middle">{item.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <SpeedOutlinedIcon fontSize="large" /> By Difficulty
                  </h5>
                  {stats.completed_by_difficulty?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Difficulty</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.completed_by_difficulty?.map((item) => (
                          <tr>
                            <td className="align-middle">
                              <Link
                                className="link"
                                state={{ byDifficulty: item.name }}
                                to={`/main/games/completed`}
                              >
                                {getDifficultyLabel(item.name)}
                              </Link>
                            </td>
                            <td className="right align-middle">{item.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <GamepadOutlinedIcon fontSize="large" /> By Genres
                  </h5>
                  {stats.completed_by_genres?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Genre</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.completed_by_genres?.map((item) => (
                          <tr>
                            <td className="align-middle">
                              <Link
                                className="link"
                                state={{ byGenre: item.name }}
                                to={`/main/games/completed`}
                              >
                                {item.name}
                              </Link>
                            </td>
                            <td className="right align-middle">{item.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <CategoryOutlinedIcon fontSize="large" /> By Themes
                  </h5>
                  {stats.completed_by_themes?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Theme</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.completed_by_themes?.map((item) => (
                          <tr>
                            <td className="align-middle">
                              <Link
                                className="link"
                                state={{ byTheme: item.name }}
                                to={`/main/games/completed`}
                              >
                                {item.name}
                              </Link>
                            </td>
                            <td className="right align-middle">{item.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <CalendarMonthOutlinedIcon fontSize="large" /> Most Active
                    Years
                  </h5>
                  {stats.completed_by_year?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Year</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.completed_by_year?.slice(0, 10).map((item) => (
                          <tr>
                            <td>
                              <Link
                                className="link"
                                state={{ byYear: item.name }}
                                to={`/main/games/completed`}
                              >
                                {item.name}
                              </Link>
                            </td>
                            <td className="right">{item.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="container-fluid">
      {isLoading ? (
        <div className="spinner-grow" role="status" />
      ) : (
        <RenderGameStats />
      )}
    </div>
  );
};

export default HomePage;
