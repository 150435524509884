import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useOutletContext } from "react-router-dom";
import GameService from "../../services/GameService";
import { Pagination, Tooltip } from "@mui/material";
import { IGame } from "../../types/Game";
import useAlert from "../../hooks/userAlert";
import GameCard from "../card/GameCard";
import GameCardActions from "../card/GameCardActions";
import { Button, Collapse } from "react-bootstrap";
import { IOption, IPlatformOption } from "../../types/Option";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import VideogameAssetOutlinedIcon from "@mui/icons-material/VideogameAssetOutlined";
import GamepadOutlinedIcon from "@mui/icons-material/GamepadOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SelectOptions from "../../data/SelectOptions";
import sessionManager from "../../utils/SessionManager";
import gamesUtils from "../../utils/GamesUtils";
import GameCardDisplay from "../card/GameCardDisplay";
import { BackgroundImageOutletContext } from "../layout/MainLayout";
import { setListCollectionPageTitle, setThemeBackground } from "../../utils/Display";
import { IAccount } from "../../types/Account";
import UserHeader from "../common/UserHeader";
import { IStats } from "../../types/Stats";
import { IAccountConnections } from "../../types/AccountConnections";

type Props = {
  type: string;
};

function List(props: Props) {
  const { type } = props;

  const account: IAccount | undefined = sessionManager.getCurrentUserProfile();

  const GAMES = "/main/search/games";

  const { setAlert } = useAlert();
  const fecthErrorMessage = "We had a problem while fetching the data";
  const errorSelectOptions = "We had a problem while fetching the options data";

  const [userFollowing, setUserFollowing] = useState(
    {} as IAccountConnections
  );
  const [stats, setStats] = useState({} as IStats);
  const [platforms, setPlatforms] = useState([] as IPlatformOption[]);
  const [years, setYears] = useState([] as IOption[]);
  const [genres, setGenres] = useState([] as IOption[]);
  const [themes, setThemes] = useState([] as IOption[]);
  const [searchTermButton, setSearchTermButton] = useState("");

  const [games, setGames] = useState([] as IGame[]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(0);
  const [gameCount, setGameCount] = useState(0);

  const [filterTerm, setFilterTerm] = useState("");
  const { setBackgroundImage } =
    useOutletContext<BackgroundImageOutletContext>();

  const filteredGames = useMemo(() => {
    return gamesUtils.filterGames(filterTerm, games);
  }, [filterTerm, games]);

  const location = useLocation();
  let { state } = location;

  if (!state) {
    state = {
      searchTerm: "",
      byYear: "",
      byPlatform: "",
      byPlatformLogo: "",
      byGenre: "",
      byTheme: "",
      byCompany: "",
      byDifficulty: "",
      byRating: "",
      page: 1,
      pageSize: 30,
    };
  }

  // Games filters state
  const [filters, setFilters] = useState({
    searchTerm: state.searchTerm,
    byYear: state.byYear,
    byPlatform: state.byPlatform,
    byPlatformLogo: state.byPlatformLogo,
    byGenre: state.byGenre,
    byTheme: state.byTheme,
    byCompany: state.byCompany,
    byDifficulty: state.byDifficulty,
    byRating: state.byRating,
    page: state.page,
    pageSize: state.pageSize,
  });

  useEffect(() => {
    setThemeBackground(setBackgroundImage);
    setListCollectionPageTitle(type)
    fecthGames();
    fetchGamingStats();
    fetchFollowingConnections();
    fetchYears();
    fetchPlatforms();
    fetchGenres();
    fetchThemes();
    // eslint-disable-next-line
  }, [type, filters]);

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  const fetchYears = async () => {
    const list = [] as IOption[];
    const currentYear = new Date().getFullYear();
    for (let index = currentYear; index >= 1970; index--) {
      let value = index.toString();
      const option: IOption = {
        value: value,
        label: value,
      };

      list.push(option);
    }
    setYears(list);
  };

  const fetchFollowingConnections = async () => {
    try {
      const response = await GameService.getUserConnections("following");
      setUserFollowing(response.data);
    } catch (error) {
      handleResponseError(error, fecthErrorMessage);
    }
  };

  const fetchGamingStats = async () => {
    try {
      const response = await GameService.getGamingStats();
      setStats(response.data);
    } catch (error) {
      handleResponseError(error, fecthErrorMessage);
    }
  };

  const fetchPlatforms = async () => {
    try {
      const res = await GameService.getPlatforms();
      setPlatforms(res.data);
    } catch (error) {
      handleResponseError(error, errorSelectOptions);
    }
  };

  const fetchGenres = async () => {
    try {
      const res = await GameService.getGenres();
      setGenres(res.data);
    } catch (error) {
      handleResponseError(error, errorSelectOptions);
    }
  };

  const fetchThemes = async () => {
    try {
      const res = await GameService.getThemes();
      setThemes(res.data);
    } catch (error) {
      handleResponseError(error, errorSelectOptions);
    }
  };

  const fecthGames = async () => {
    try {
      setIsLoading(true);

      const response = await GameService.getGamesWithParams(type, filters);

      setIsLoading(false);
      setGames(response.data.games);
      setTotalPages(response.data.totalPages);
      setGameCount(response.data.gameCount);
    } catch (error: any) {
      setIsLoading(false);
      handleResponseError(error, fecthErrorMessage);
    }
  };

  const onChangeFilter = (e: any) => {
    setFilters({ ...filters, [e.target.name]: e.target.value, page: 1 });
  };

  const getPlatformLogoURL = (platform: any) => {
    const plat = platforms.find((item) => {
      return item.label === platform;
    });
    if (plat) {
      return plat.logo;
    }
  };

  const onChangeFilterPlatform = (e: any) => {
    const logoURL = getPlatformLogoURL(e.target.value);
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
      byPlatformLogo: logoURL,
    });
  };

  const clearFilters = async (e: any) => {
    setFilters({
      searchTerm: "",
      byYear: "",
      byPlatform: "",
      byPlatformLogo: "",
      byGenre: "",
      byTheme: "",
      byCompany: "",
      byDifficulty: "",
      byRating: "",
      page: 1,
      pageSize: 30,
    });
  };

  const onKeydown = (e: any) => {
    if (e.key === "Enter") {
      setFilters({ ...filters, [e.target.name]: e.target.value });
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setFilters({ ...filters, page: value });
  };

  const onChangeSearchTerm = (e: any) => {
    setSearchTermButton(e.target.value)
  };

  const onSearchButtonClick = (e: any) => {
    setFilters({ ...filters, searchTerm: searchTermButton, page: 1 });
  };

  const RenderFilters = () => {
    return (
      <div className="game-solid-background p-4 mb-2">
        <div className="row pb-2">
          <div className="col-sm-12 col-md-4 my-2">
            <div className="input-group-sm">
              <EventAvailableIcon sx={{ marginRight: 0.5 }} />
              <label>
                <span className="text-capitalize">{type}</span> Year
              </label>
              <select
                className="form-select"
                value={filters.byYear}
                name="byYear"
                onChange={onChangeFilter}
              >
                <option key="" value="" />
                {years.map((name) => {
                  return (
                    <option key={name.value} value={name.label}>
                      {name.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 my-2">
            <div className="input-group-sm">
              <VideogameAssetOutlinedIcon sx={{ marginRight: 0.5 }} />
              <label>Platform</label>
              <select
                className="form-select"
                value={filters.byPlatform}
                name="byPlatform"
                onChange={onChangeFilterPlatform}
              >
                <option key="" value="" />
                {platforms.map((name) => {
                  return (
                    <option key={name.value} value={name.label}>
                      {name.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 my-2">
            <div className="input-group-sm">
              <GamepadOutlinedIcon sx={{ marginRight: 0.5 }} />
              <label>Genre</label>
              <select
                className="form-select"
                value={filters.byGenre}
                name="byGenre"
                onChange={onChangeFilter}
              >
                <option key="" value="" />
                {genres.map((name) => {
                  return (
                    <option key={name.value} value={name.label}>
                      {name.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 my-2">
            <div className="input-group-sm">
              <CategoryOutlinedIcon sx={{ marginRight: 0.5 }} />
              <label>Theme</label>
              <select
                className="form-select"
                value={filters.byTheme}
                name="byTheme"
                onChange={onChangeFilter}
              >
                <option key="" value="" />
                {themes.map((name) => {
                  return (
                    <option key={name.value} value={name.label}>
                      {name.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 my-2">
            <div className="input-group-sm">
              <SpeedOutlinedIcon sx={{ marginRight: 0.5 }} />
              <label>Difficulty</label>
              <select
                className="form-select"
                value={filters.byDifficulty}
                name="byDifficulty"
                onChange={onChangeFilter}
              >
                <option key="" value="" />
                {SelectOptions.difficulty.map((name) => {
                  return (
                    <option key={name.value} value={name.value}>
                      {name.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 my-2">
            <div className="input-group-sm">
              <StarOutlineOutlinedIcon sx={{ marginRight: 0.5 }} />
              <label>Rating</label>
              <select
                className="form-select"
                value={filters.byRating}
                name="byRating"
                onChange={onChangeFilter}
              >
                <option key="" value="" />
                {SelectOptions.ratings.map((name) => {
                  return (
                    <option key={name.value} value={name.value}>
                      {name.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="pb-3">
            <div className="input-group-sm">
              <SearchIcon sx={{ color: "white", mr: 0.5, my: 0.5 }} />
              <label>Find games on current page</label>
              <input
                type="text"
                className="form-control"
                id="input-with-sx"
                value={filterTerm}
                onChange={(e) => setFilterTerm(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row d-flex align-items-center">
        <UserHeader account={account} stats={stats} following={userFollowing}/>
      </div>
      {isLoading ? (
        <div className="spinner-grow" role="status" />
      ) : (
        <>          
          <div className="row d-flex align-items-center pb-2">
            <div className="col">
              <span className="gaming-count">{gameCount}</span>
              <span className="gaming-section">
                {" "}
                {type === "total" ? "overall" : type} games
              </span>
            </div>
          </div>

          <div className="row d-flex align-items-center">
            <div className="col-sm-12 col-md-11">
              <div className="input-group">
                <input
                  type="text"
                  name="searchTerm"
                  className="form-control"
                  placeholder={`Search for ${type} games`}
                  onKeyDown={onKeydown}
                  onChange={onChangeSearchTerm}
                />
                <button
                  className="btn-sm btn-outline"
                  type="button"
                  id="button-search"
                  onClick={onSearchButtonClick}
                >
                  <SearchOutlinedIcon fontSize="small" />
                </button>
              </div>
            </div>
            <div className="col-sm-12 col-md-1">
              <div>
                <Tooltip title="Clear Filters" arrow>
                  <Button
                    className="button-outline-custom"
                    size="sm"
                    onClick={clearFilters}
                  >
                    <FilterAltOffOutlinedIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col">
              <Tooltip title="Show/Hide Filters" arrow>
                <Button
                  className="button-outline-custom"
                  size="sm"
                  onClick={() => setOpen(!open)}
                  aria-controls="filter-collapse"
                  aria-expanded={open}
                >
                  <FilterAltOutlinedIcon fontSize="small" />
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className="row">
            <Collapse in={open}>
              <div id="filter-collapse">
                <RenderFilters />
              </div>
            </Collapse>
          </div>

          {filteredGames?.length === 0 && (
            <div className="row">
              <h6>No current gaming data to be displayed.</h6>
              <p>
                <span>
                  Start by searching and adding games from the{" "}
                  <Link className="link" to={GAMES}>
                    <div className="d-flex align-items-center">
                      <VideogameAssetOutlinedIcon /> GAMES
                    </div>
                  </Link>{" "}
                  section.
                </span>
              </p>
            </div>
          )}

          <div className="row no-gutters">
            {filteredGames.map((game, index) => (
              <div
                key={index}
                className="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-2"
              >
                <GameCard
                  game={game}
                  display={<GameCardDisplay game={game} />}
                  action={<GameCardActions game={game} type={type} />}
                />
              </div>
            ))}
          </div>

          <div className="mt-4">
            <Pagination
              showFirstButton
              showLastButton
              shape="rounded"
              color="secondary"
              size="large"
              count={totalPages}
              page={filters.page}
              onChange={handlePageChange}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default List;
