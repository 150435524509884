import { Outlet, useNavigate } from 'react-router-dom';
import assets from '../../assets';
import AlertPopup from '../../components/alert/AlertPopup';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import LineAxisOutlinedIcon from '@mui/icons-material/LineAxisOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { setMainPageTitle } from '../../utils/Display';


type Props = {};

const LandingPage = (props: Props) => {

  setMainPageTitle();

  const navigate = useNavigate();

  const login = () => {
    navigate("/login");
  };

  const register = () => {
    navigate("/register");
  };

  return (
    <div>

      <section id="authentication">
        <div className='container-fluid' style={{backgroundImage: `url(${assets.images.pattern})`}}>
          <div className='row justify-content-center'>
            <AlertPopup />
            <Outlet />
          </div>            
        </div>
      </section>

      <section id="title" className="gradient-background">
        <div className="container col-xxl-8 px-4 pt-3">
          <div className='row'>
            <h1 className="app-title">The Game Stack</h1>
          </div>
          <div className="row flex-lg-row-reverse align-items-center g-5 pt-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img src={assets.carousel.homePage} className="d-block mx-lg-auto img-fluid" alt="landing" height="200" loading="lazy" />
            </div>
            <div className="col-lg-6">
              <h3 className="display-6 fw-bold text-body-emphasis lh-1 mb-3">Never lose track of your gaming experiences.</h3>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                <button type="button" onClick={e => login()} className="btn button-custom btn-lg px-4 me-md-2">
                  <LoginIcon /> Log In
                </button>
                <button type="button" onClick={e => register()} className="btn button-outline-custom btn-lg px-4">
                  <PersonAddAlt1Icon/> Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className='feature-section'>
        <div className="container mt-3">
          <div className="row g-4 py-3 row-cols-1 row-cols-lg-3">
            <div className="col d-flex align-items-start">
              <div className="app-feature icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                <SportsEsportsOutlinedIcon fontSize='large' />
              </div>
              <div>
                <h3 className="app-feature fs-2 text-body-emphasis">Games</h3>
                <p>Explore games directly from our IGDB database integration and build your gaming collections.</p>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div className="app-feature icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                <SyncAltOutlinedIcon fontSize='large' />
              </div>
              <div>
                <h3 className="app-feature fs-2 text-body-emphasis">Import/Export</h3>
                <p>Move your games in and out of the platform. Apply batching updates and keep your own data backups.</p>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div className="app-feature icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                <LineAxisOutlinedIcon fontSize='large' />
              </div>
              <div>
                <h3 className="app-feature fs-2 text-body-emphasis">Gaming Stats</h3>
                <p>Visualise your games stats based on a number of gaming metrics.</p>
              </div>
            </div>
          </div>
          <div className="row g-4 py-3 row-cols-1 row-cols-lg-3">
            <div className="col d-flex align-items-start">
              <div className="app-feature icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                <Diversity3OutlinedIcon fontSize='large' />
              </div>
              <div>
                <h3 className="app-feature fs-2 text-body-emphasis">Build Connections</h3>
                <p>Grow a gaming community where each member's experiences, reviews and recommendations are shared.</p>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div className="app-feature icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                <ReviewsOutlinedIcon fontSize='large' />
              </div>
              <div>
                <h3 className="app-feature fs-2 text-body-emphasis">Reviews</h3>
                <p>Write and share gaming reviews, ratings and experiences with our community.</p>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div className="app-feature icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                <FormatListBulletedOutlinedIcon fontSize='large' />
              </div>
              <div>
                <h3 className="app-feature fs-2 text-body-emphasis">Lists</h3>
                <p>Create gaming lists across any themes and interests.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="partner" className='partner-section'>
        <div className="py-4">
          <div className="p-2 text-center bg-body-tertiary">

            <div className="container">
              <div className="row align-items-center">
                <span className='pb-1'>Powered by</span>
                <div className="col">
                  <a className='link' href="https://www.igdb.com/" id="logo" target="_blank" rel="noopener noreferrer">
                    {/* <img src={assets.images.partnerLogo} alt="igdb" height="50" /> */}
                    <h2>IGDB</h2>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="footer" className="gradient-background">
        <div className="container">
          <footer className="row">
            <div className="col py-5">
              © The Game Stack - {new Date().getFullYear()}
            </div>
          </footer>
        </div>
      </section>

    </div>
  );
};

export default LandingPage;