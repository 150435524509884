import assets from "../assets";
import appConfigs from "../configs/appConfigs";
import { IGame } from "../types/Game";
import { IReview } from "../types/Review";
import { capitalize } from "./Formatter";
import sessionManager from "./SessionManager";

export const setGameImageBackground = (
  game: IGame,
  callback: (image: string) => void
) => {
  if (game.game_artworks) {
    callback(game.game_artworks[0]);
  } else if (game.game_screenshots) {
    callback(game.game_screenshots[0]);
  } else if (game.game_cover_url) {
    callback(game.game_cover_url);
  } else {
    setThemeBackground(callback);
  }
};

export const setReviewImageBackground = (
  review: IReview,
  callback: (image: string) => void
) => {

  if (review.game_screenshot_url) {
    callback(review.game_screenshot_url);
  } else {
    setThemeBackground(callback);
  }
};

export const setThemeBackground = (callback: (image: string) => void) => {
  callback(assets.themes.hades);
};

export const setGameCollectionPageTitle = (title: string) => {
  document.title = `${title} | ${sessionManager.getFormattedUserName()} Game Collection | ${
    appConfigs.mainTitle
  }`;
};

export const setGameInfoPageTitle = (title: string) => {
  document.title = `${title} | ${appConfigs.mainTitle}`;
};

export const setListCollectionPageTitle = (type: string) => {
  document.title = `${sessionManager.getFormattedUserName()} 
  ${capitalize(type)} Games | ${appConfigs.mainTitle}`;
};

export const getCollectionPageTitle = () => {
  return `${sessionManager.getFormattedUserName()} Game Collection`;
};

export const setUserConnectionsPageTitle = () => {
  document.title = `${sessionManager.getFormattedUserName()} 
  Connections | ${appConfigs.mainTitle}`;
};

export const setGamesPageTitle = () => {
  document.title = `Games | ${appConfigs.mainTitle}`;
};

export const setReviewsPageTitle = () => {
  document.title = `Reviews | ${appConfigs.mainTitle}`;
};

export const setMembersPageTitle = () => {
  document.title = `Members | ${appConfigs.mainTitle}`;
};

export const settGamesImportPageTitle = () => {
  document.title = `Import Games | Utilities | ${appConfigs.mainTitle}`;
};

export const setGamesExportPageTitle = () => {
  document.title = `Export Games | Utilities | ${appConfigs.mainTitle}`;
};

export const setMainPageTitle = () => {
  document.title = appConfigs.mainTitle;
};

export const setResourceNotFoundPageTitle = () => {
  document.title = `Resource Not Found | ${appConfigs.mainTitle}`;
};

export const setGamingTablePageTitle = () => {
  document.title = `Gaming Tables | Stats | ${appConfigs.mainTitle}`;
};

export const setGamingChartsPageTitle = () => {
  document.title = `Gaming Charts | Stats | ${appConfigs.mainTitle}`;
};

export const getUserGameReviewTitle = (action: string) => {
  return action === "ADD" ? 'Write a Review' : 'Edit Game Review';
};
