import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { setGameCollectionPageTitle } from "../../utils/Display";
import { IReview } from "../../types/Review";
import GameReview from "../../components/forms/GameReview";

const AddReviewPage = () => {

  const location = useLocation();
  const { state } = location;

  const [review, setReview] = useState(state.review as IReview);

  useEffect(() => {
    setGameCollectionPageTitle(review.game_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <GameReview action="ADD" review={review} setReview={setReview} />;
};

export default AddReviewPage;
