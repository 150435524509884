import { IRelatedCompany, IRelatedGame } from "./Game";

export interface IReview {
    pk: string;
    sk?: string;
    game_title: string;
    game_slug: string;
    game_cover_url: string;
    game_screenshot_url?: string;
    game_release: string;
    game_genres: [];
    game_released_platforms: [];
    game_parent?: IRelatedGame; 
    game_companies: IRelatedCompany[] | [];
    game_themes: [];
    game_category: string;
    game_platform: string;
    game_platform_slug: string;
    game_status: string;
    game_duration: string;
    game_difficulty: string;
    game_platform_logo_url: string;
    reviewer: string;
    review_date: string;
    game_rating: string;
    rating_visual: string;
    rating_audio: string;
    rating_gameplay: string;
    rating_story: string;
    rating_control: string;
    review_title: string;
    review_description: string;
    created_at?: string;
    updated_at?: string;
}

export const EMPTY_REVIEW = {
    pk: "",
    sk: "",
    game_title: "",
    game_slug: "",
    game_cover_url: "",
    game_screenshot_url: "",
    game_release: "",
    game_genres: [],
    game_companies: [],
    game_themes: [],
    game_platform: "",
    game_platform_slug: "",
    game_status: "",
    game_duration: "",
    game_difficulty: "",
    game_category: "",
    game_platform_logo_url: "",
    review_date: "",
    reviewer: "",
    game_rating: "",
    rating_visual: "",
    rating_audio: "",
    rating_gameplay: "",
    rating_story: "",
    rating_control: "",
    review_title: "",
    review_description: "",
  };