import http from "./HttpService";
import sessionManager from "../utils/SessionManager";


const getAllUserGames = async () => {
    const headers = {
        'x-user-id': sessionManager.getCurrentUserProfileId()
    };
    const data = await http.get("/games", {headers});
    return data;
};

const getGamesWithParams = async (statusOrFavourite, params) => {
    let {searchTerm, byYear, byPlatform, byGenre, byTheme, byCompany, byDifficulty, byRating, page, pageSize} = params;

    let endpoint = "/games".concat(
        statusOrFavourite === "favourites" ? "?favourite=true" : "?status=" + statusOrFavourite,
        searchTerm ? "&title=" + searchTerm : "",
        byYear ? "&year=" + byYear : "",
        byPlatform ? "&platform=" + byPlatform : "",
        byGenre ? "&genre=" + byGenre : "",
        byTheme ? "&theme=" + byTheme : "",
        byCompany ? "&company=" + byCompany : "",
        byDifficulty ? "&difficulty=" + byDifficulty : "",
        byRating ? "&rating=" + byRating : "",
        page ? "&page=" + page : "",
        pageSize ? "&pagesize=" + pageSize : ""
    );

    const headers = {
        'x-user-id': sessionManager.getCurrentUserProfileId()
    };
    const data = await http.get(endpoint, {headers});
    return data;
};

const getGameByID = async (id) => {
    const headers = {
        'x-user-id': sessionManager.getCurrentUserProfileId()
    };
    const data = await http.get("/games/" + id, {headers});
    return data;
};

const getUserGamesByGameID = async (externalId, allUsers) => {
    let endpoint = "/games/" + externalId + "/external".concat(
        allUsers ? "" : "?user=" + sessionManager.getCurrentUserProfileId()
    );

    const data = await http.get(endpoint);
    return data;
};

const addGame = async (data) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.post("/games", data, {headers});
    generateGamingStats();
    return result;
};

const deleteGame = async (id) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.delete("/games/" + id, {headers});
    generateGamingStats();
    return result;
};

const updateGame = async (id, data) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.put("/games/" + id, data, {headers});
    generateGamingStats();
    return result;
};

const getAllReviews = async (params) => {
    let {byPlatform, byGenre, byTheme, byRating, page, pageSize, sortField, sortOrder} = params;

    let endpoint = "/reviews".concat(
        page ? "?page=" + page : "",
        pageSize ? "&pagesize=" + pageSize : "",
        byPlatform ? "&platform=" + byPlatform : "",
        byGenre ? "&genre=" + byGenre : "",
        byTheme ? "&theme=" + byTheme : "",
        byRating ? "&rating=" + byRating : "",
        sortField ? "&sort=" + sortField : "",
        sortOrder ? "&order=" + sortOrder : "",
    );

    const headers = {
        'x-user-id': sessionManager.getCurrentUserProfileId()
    };
    const data = await http.get(endpoint, {headers});
    return data;
};

const getReviewByID = async (id) => {
    const headers = {
        'x-user-id': sessionManager.getCurrentUserProfileId()
    };
    const data = await http.get("/reviews/" + id, {headers});
    return data;
};

const addReview = async (data) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.post("/reviews", data, {headers});
    generateGamingStats();
    return result;
};

const deleteReview = async (id) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.delete("/reviews/" + id, {headers});
    generateGamingStats();
    return result;
};

const updateReview = async (id, data) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.put("/reviews/" + id, data, {headers});
    generateGamingStats();
    return result;
};

const getGameReviews = async (gameId) => {
    let endpoint = "/games/" + gameId + "/reviews";

    const data = await http.get(endpoint);
    return data;
};

const parseMultiValueParams = (endpoint, paramName, values) => {
    endpoint += endpoint === "/services/search" ? 
        `?${paramName}=${values}` : `&${paramName}=${values}`
    return endpoint;
  }
  
  const buildSearchEndpoint = (searchParams) => {
    let {searchTerm, byPlatform, byGenre, byTheme, byCategory, byMode, 
        byPerspective, page, pageSize, sortField, sortOrder} = searchParams;
  
    let endpoint = "/services/search";

    if (searchTerm) {
        endpoint = parseMultiValueParams(endpoint, "title", searchTerm);   
    }
  
    if (byPlatform) {
        endpoint = parseMultiValueParams(endpoint, "platforms", byPlatform);
    }
  
    if (byGenre) {
        endpoint = parseMultiValueParams(endpoint, "genres", byGenre);
    }
    
    if (byTheme) {
        endpoint = parseMultiValueParams(endpoint, "themes", byTheme);
    }
  
    if (byCategory) {
        endpoint = parseMultiValueParams(endpoint, "categories", byCategory);
    }
  
    if (byMode) {
        endpoint = parseMultiValueParams(endpoint, "modes", byMode);
    }
  
    if (byPerspective) {
        endpoint = parseMultiValueParams(endpoint, "perspectives", byPerspective);
    }

    if (page) {
        endpoint = parseMultiValueParams(endpoint, "page", page);
    }

    if (pageSize) {
        endpoint = parseMultiValueParams(endpoint, "pagesize", pageSize);
    }

    if (sortField) {
        endpoint = parseMultiValueParams(endpoint, "sort", sortField);
    }

    if (sortOrder) {
        endpoint = parseMultiValueParams(endpoint, "order", sortOrder);
    }
  
    return endpoint;
  };

const searchGames = async (params) => {
    console.log("params: ", params);

    const endpoint = buildSearchEndpoint(params);

    console.log("endpoint: ", endpoint);

    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get(endpoint, {headers});
    return data;
};

const getServiceGameById = async (id) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/services/game?slug=" + id + "&details=complete", {headers});
    return data;
};

const getPlatforms = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/services/platforms", {headers});
    return data;
};

const getGenres = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/services/genres", {headers});
    return data;
};

const getCompanies = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/services/companies", {headers});
    return data;
};

const getThemes = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/services/themes", {headers});
    return data;
};

const processFile = async (data, operation) => {
    const headers = {
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.post("/games/import?operation=" + operation, data, {headers});
    generateGamingStats();
    return result;
};

const requestFile = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.get("/games/export", {headers});
    return result;
};

const getGamingStats = async () => {
    const headers = {
        'x-user-id': sessionManager.getCurrentUserProfileId()
    };
    const data = await http.get("/games/stats", {headers});
    return data;
};

const generateGamingStats = async () => {
    const headers = {
        'x-user-id': sessionManager.getCurrentUserProfileId()
    };
    http.put("/games/stats", {}, {headers});
};

const getUserProfile = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/accounts/profile", {headers});
    return data;
};

const upsertUserProfile = async (data) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.put("/accounts/profile", data, {headers});
    return result;
};

const searchAccountsByName = async (name) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/accounts/search?name=" + name, {headers});
    return data;
};

const getUserProfileById = async (userId) => {
    const headers = {
        'x-user-id': userId
    };
    const data = await http.get("/accounts/profile", {headers});
    return data;
};

const followUserAccount = async (data) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.post("/accounts/connections", data, {headers});
    return result;
};

const unfollowUserAccount = async (data) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.delete("/accounts/connections", {data, headers});
    return result;
};

const getUserConnections = async (type) => {
    const headers = {
        'x-user-id': sessionManager.getCurrentUserProfileId()
    };
    const data = await http.get(`/accounts/connections?type=${type}`, {headers});
    return data;
};

const getPlatformsGameSearch = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/services/platforms-search", {headers});
    return data;
};

const getGenresGameSearch = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/services/genres-search", {headers});
    return data;
};

const getThemesGameSearch = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/services/themes-search", {headers});
    return data;
};

const getPlayerPerspectivesGameSearch = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/services/player-perspectives-search", {headers});
    return data;
};

const getGameModesGameSearch = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/services/game-modes-search", {headers});
    return data;
};

const getGameCategoriesGameSearch = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/services/game-categories-search", {headers});
    return data;
};

const service = {
    getAllUserGames,
    getGamesWithParams,
    getGameByID,
    getUserGamesByGameID,
    addGame,
    deleteGame,
    updateGame,
    getAllReviews,
    getReviewByID,
    addReview,
    deleteReview,
    updateReview,
    getGameReviews,
    searchGames,
    getServiceGameById,
    getPlatforms,
    getGenres,
    getCompanies,
    getThemes,
    processFile,
    requestFile,
    getGamingStats,
    generateGamingStats,
    getUserProfile,
    upsertUserProfile,
    searchAccountsByName,
    getUserProfileById,
    followUserAccount,
    unfollowUserAccount,
    getUserConnections,
    getPlatformsGameSearch,
    getGenresGameSearch,
    getThemesGameSearch,
    getPlayerPerspectivesGameSearch,
    getGameModesGameSearch,
    getGameCategoriesGameSearch,
};

export default service;