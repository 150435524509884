import { Stack } from "@mui/material";
import { IReview } from "../../types/Review";
import { dateYear, formatDateWithMonthName } from "../../utils/Formatter";
import GameBadges from "../common/GameBadges";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import MusicNoteOutlinedIcon from "@mui/icons-material/MusicNoteOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import GamepadOutlinedIcon from "@mui/icons-material/GamepadOutlined";
import { getRatingLabel } from "../../utils/GamesUtils";
import { Link } from "react-router-dom";

type Props = {
  review: IReview;
};

const ReviewCard = (props: Props) => {
  const { review } = props;

  const viewGameReview = "/main/reviews/view/";

  return (
    <div className="container-fluid p-3">
      <div className="row">
        <div className="col-lg-1 align-self-center">
          <Stack direction="column" alignItems="center" justifyContent="center">
            <img src={review.game_cover_url} alt="game cover" width="70" />
          </Stack>
        </div>
        <div className="col-lg-11 align-self-center">
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <div className="row">
                <Link
                  className="link"
                  to={viewGameReview + review.pk.split("#")[1]}
                >
                  <h4 className="game-review-rating">
                    {review.game_title} ({dateYear(review.game_release)})
                  </h4>
                </Link>
              </div>
              <div className="row mt-2">
                <div className="col align-self-center review-card-text">
                  {review.review_description.slice(0, 200)}...
                </div>
              </div>
            </div>
            <div className="col align-self-center">
              <Stack
                sx={{ backgroundColor: "lightgrey", padding: 1 }}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  src={review.game_platform_logo_url}
                  alt="platform logo"
                  width="70"
                />
              </Stack>
            </div>
            <div className="col align-self-center">
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <GameBadges status={review.game_status} />{" "}
                {review.game_status.toUpperCase()}
              </Stack>
            </div>
            <div className="col align-self-center">
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <TimerOutlinedIcon /> {review.game_duration}
              </Stack>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <div className="game-review-text">
            Reviewed by {review.reviewer} on{" "}
            {formatDateWithMonthName(review.review_date)}
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <StarOutlinedIcon /> {review.game_rating}{" "}
          {getRatingLabel(review.game_rating)}
        </div>
        <div className="col">
          <DesktopWindowsOutlinedIcon /> {review.rating_visual} Visual
        </div>
        <div className="col">
          <MusicNoteOutlinedIcon /> {review.rating_audio} Audio
        </div>
        <div className="col">
          <GamepadOutlinedIcon /> {review.rating_control} Control
        </div>
        <div className="col">
          <AutoStoriesOutlinedIcon /> {review.rating_story} Story
        </div>
        <div className="col">
          <SportsEsportsOutlinedIcon /> {review.rating_gameplay} Gameplay
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
