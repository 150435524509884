import { useEffect, useState } from "react";
import useAlert from "../../hooks/userAlert";
import GameService from "../../services/GameService";
import { IAccount } from "../../types/Account";
import { Button } from "react-bootstrap";
import { useNavigate, useOutletContext } from "react-router-dom";
import sessionManager from "../../utils/SessionManager";
import { Tooltip } from "@mui/material";
import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { IConnection } from "../../types/Connection";
import { formatTimestampWithMonthName } from "../../utils/Formatter";
import { BackgroundImageOutletContext } from "../../components/layout/MainLayout";
import { setMembersPageTitle, setThemeBackground } from "../../utils/Display";

type Props = {};

const FindConnectionsPage = (props: Props) => {
  const canEdit = sessionManager.isAuthUserCurrentProfileOwner();

  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [accounts, setAccounts] = useState([] as IAccount[]);

  const navigate = useNavigate();
  const { setAlert } = useAlert();

  const followUserMessage = "You are now following ";
  const followUserErrorMessage =
    "We had a problem while requesting to follow the selected user";
  const fecthErrorMessage =
    "We had a problem while fetching your account details";

  const { setBackgroundImage } =
    useOutletContext<BackgroundImageOutletContext>();

  useEffect(() => {
    setThemeBackground(setBackgroundImage);
    setMembersPageTitle();
    // eslint-disable-next-line
  }, []);

  const onChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = async (e: any) => {
    e.preventDefault();
    try {
      if (searchValue) {
        setIsLoading(true);
        const response = await GameService.searchAccountsByName(searchValue);
        setAccounts(response.data);
        setIsLoading(false);
      }
    } catch (err: any) {
      setIsLoading(false);
      setAlert(fecthErrorMessage, "error");
    }
  };

  const goToUserProfile = async (e: any, account: IAccount) => {
    const userId = account.pk!.split("#")[1];
    await sessionManager.setCurrentUserProfile(userId);
    navigate("/main");
    document.location.reload();
  };

  const followUserProfile = async (e: any, account: IAccount) => {
    const userId = account.pk!.split("#")[1];
    try {
      const request: IConnection = { sk: userId };
      await GameService.followUserAccount(request);
      setAlert(followUserMessage + account.display_name, "success");
    } catch (err: any) {
      setAlert(followUserErrorMessage, "error");
    }
  };

  const RenderResults = () => {
    return (
      <div className="row">
        {accounts.length ? (
          <div className="col">
            <table className="table table-dark table-striped">
              <tbody>
                {accounts?.map((account) => (
                  <tr>
                    <td>
                      {
                        <img
                          src={account.profile_image_url}
                          className="rounded float-left"
                          alt="avatar"
                          width="80"
                        />
                      }
                    </td>
                    <td className="align-middle">
                      <span className="large-text align-middle">
                        {account.display_name}{" "}
                      </span>
                      {account.location_image_url ? (
                        <img
                          src={account.location_image_url}
                          height={15}
                          className="align-middle"
                          alt="flag"
                        />
                      ) : (
                        <></>
                      )}
                      <br />
                      <span className="small-text">
                        Member since{" "}
                        {formatTimestampWithMonthName(account.created_at)}
                      </span>
                      <br />
                      <br />
                      {account.about_me}
                    </td>
                    <td className="align-middle">
                      <Tooltip title="Go to profile">
                        <Button
                          className="button-custom"
                          size="sm"
                          onClick={(e) => goToUserProfile(e, account)}
                        >
                          <SportsEsportsOutlinedIcon />
                        </Button>
                      </Tooltip>
                      <br />
                      <br />
                      {canEdit && (
                        <Tooltip title="Follow user">
                          <Button
                            className="button-custom"
                            size="sm"
                            onClick={(e) => followUserProfile(e, account)}
                          >
                            <PersonAddAltOutlinedIcon />
                          </Button>
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="col">
            Matching gaming connections results will be shown here.
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row mb-1">
        <h6 className="page-header">Find New Gaming Connections</h6>
      </div>

      <form onSubmit={handleSearch}>
        <div className="row mb-5">
          <div className="col">
            <div className="input shadow-none align-items-center">
              <input
                type="text"
                name="game_search"
                className="form-control"
                placeholder="Search by gamer tag..."
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </form>
      {isLoading ? (
        <div className="spinner-grow" role="status" />
      ) : (
        <RenderResults />
      )}
    </div>
  );
};

export default FindConnectionsPage;
