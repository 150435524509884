import { useNavigate, Link } from "react-router-dom";
import GameService from "../../services/GameService";
import { Chip, Rating, Stack } from "@mui/material";
import useAlert from "../../hooks/userAlert";
import { Button } from "react-bootstrap";
import sessionManager from "../../utils/SessionManager";
import { formatDateWithMonthName, formatGameDuration } from "../../utils/Formatter";
import ImageModal from "../modal/ImageModal";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import MusicNoteOutlinedIcon from "@mui/icons-material/MusicNoteOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import GamepadOutlinedIcon from "@mui/icons-material/GamepadOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { StarBorder } from "@mui/icons-material";
import { findGameDeveloper, getDifficultyLabel, getRatingLabel } from "../../utils/GamesUtils";
import { getUserGameReviewTitle } from "../../utils/Display";
import { IReview } from "../../types/Review";
import colorConfigs from "../../configs/colorConfigs";
import { styled } from "@mui/system";
import StatRate from "../stats/StatRate";
import GameBadges from "../common/GameBadges";

type Props = {
  action: string;
  gameId?: string;
  review: IReview;
  setReview: (review: IReview) => void;
};

const GameReview = (props: Props) => {
  const { action } = props;
  const { gameId } = props;
  const { review } = props;
  const setReview = props.setReview;

  const ratingEmptyColor = "#aeaeae";

  const pageTitle = getUserGameReviewTitle(action);

  const canEdit = sessionManager.isAuthUserCurrentProfileOwner();

  const { setAlert } = useAlert();
  const successMessage = "Game review added/updated successfully";
  const errorMessage = "We had a problem while adding/updating the game review";

  const navigate = useNavigate();

  const StyledChip = styled(Chip)({
    marginRight: 3,
  });

  const PointerChip = styled(StyledChip)({
    cursor: "pointer",
  });

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  const upsertData = async (e: any) => {
    if (action === "ADD") {
      addReview(e);
    } else if (action === "UPDATE") {
      updateReview(e);
    }
  };

  const updateReview = async (e: any) => {
    e.preventDefault();
    try {
      await GameService.updateReview(gameId, review);
      setAlert(successMessage, "success");
      navigate(-1);
    } catch (error) {
      handleResponseError(error, errorMessage);
    }
  };

  const addReview = async (e: any) => {
    e.preventDefault();
    try {
      await GameService.addReview(review);
      setAlert(successMessage, "success");
      navigate(-1);
    } catch (error) {
      handleResponseError(error, errorMessage);
    }
  };

  const closeForm = async (e: any) => {
    navigate(-1);
  };

  const onChange = (e: any) => {
    setReview({ ...review, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="container">
        <div className="row mb-1">
          <h6 className="page-header">{pageTitle}</h6>
        </div>
        <section id="header" className="game-solid-background">
          <div className="container p-4">
            <div className="row">
              <div className="col-lg-9">
                <h2 className="game-title display-6 fw-bold text-body-emphasis">
                  {review.game_title}
                  &nbsp;
                  <GameBadges status={review.game_status} badgeSize="large" />
                </h2>
                <h6 className="game-title">
                  {formatDateWithMonthName(review.game_release)}
                </h6>
                <h4 className="game-title">
                  {findGameDeveloper(review.game_companies)}
                  &nbsp;
                  {review.game_category && (
                    <Chip
                      className="chip-outline-custom"
                      variant="outlined"
                      size="small"
                      key={review.game_category}
                      label={review.game_category}
                    />
                  )}
                </h4>
                <div>
                  {review.game_genres?.concat(review.game_themes).map((value) => (
                    <Link
                      className="link"
                      state={{ byGenre: value }}
                      to={`/main/games/total`}
                    >
                      <PointerChip
                        className="chip-outline-custom"
                        variant="outlined"
                        size="small"
                        key={value}
                        label={value}
                      />
                    </Link>
                  ))}
                </div>
                <div className="row g-0 pt-4">
                  <div className="col d-flex justify-content-center col-sm-12 col-md-6 col-lg-3">
                    {review.game_platform_logo_url ? (
                      <div>
                        <Link
                          className="link"
                          state={{
                            byPlatform: review.game_platform,
                            byPlatformLogo: review.game_platform_logo_url,
                          }}
                          to={`/main/games/total`}
                        >
                          <img
                            src={review.game_platform_logo_url}
                            alt="platform logo"
                            width="150"
                          />
                        </Link>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="col d-flex justify-content-center col-sm-12 col-md-6 col-lg-3 mb-2">
                    <Link
                      className="link"
                      state={{ byRating: review.game_rating }}
                      to={`/main/games/total`}
                    >
                      <StatRate
                        height={150}
                        width={150}
                        rate={parseInt(review.game_rating)}
                        isPercentage={false}
                        minValue={0}
                        maxValue={10}
                        rateFontSize={35}
                        trailColor={colorConfigs.stats.statRateTrail}
                        pathColor={colorConfigs.stats.statRatePath}
                        textColor={colorConfigs.stats.statRateText}
                        icon={
                          <StarOutlineOutlinedIcon sx={{ color: "white" }} />
                        }
                        text={getRatingLabel(review.game_rating)}
                      />
                    </Link>
                  </div>
                  <div className="col d-flex justify-content-center col-sm-12 col-md-6 col-lg-3 mb-2">
                    <Link
                      className="link"
                      state={{ byDifficulty: review.game_difficulty }}
                      to={`/main/games/total`}
                    >
                      <StatRate
                        height={150}
                        width={150}
                        rate={parseInt(review.game_difficulty)}
                        isPercentage={false}
                        minValue={0}
                        maxValue={5}
                        rateFontSize={35}
                        trailColor={colorConfigs.stats.statRateTrail}
                        pathColor={colorConfigs.stats.statRatePath}
                        textColor={colorConfigs.stats.statRateText}
                        icon={<SpeedOutlinedIcon sx={{ color: "white" }} />}
                        text={getDifficultyLabel(review.game_difficulty)}
                      />
                    </Link>
                  </div>
                  <div className="col d-flex justify-content-center col-sm-12 col-md-6 col-lg-3 mb-2">
                    <StatRate
                      height={150}
                      width={150}
                      rate={formatGameDuration(review.game_duration)}
                      isPercentage={false}
                      minValue={0}
                      maxValue={0}
                      rateFontSize={35}
                      trailColor={colorConfigs.stats.statRateTrail}
                      pathColor={colorConfigs.stats.statRatePath}
                      textColor={colorConfigs.stats.statRateText}
                      icon={<TimerOutlinedIcon sx={{ color: "white" }} />}
                      text="Hour(s)"
                    />
                  </div>
                </div>
              </div>
              <div className="col col-lg-3 mt-2">
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ImageModal imageURL={review.game_cover_url} />                
                </Stack>
              </div>
            </div>
          </div>
        </section>
        <section id="review" className="game-solid-background">
          <div className="container p-4 mt-4">
            <div className="row">
              <h3 className="section-title">Game Review</h3>
            </div>
            <div className="row my-2">
              <div className="form-group">
                <div className="mb-2 d-flex align-items-center">
                  <ChatOutlinedIcon sx={{ marginRight: 0.5 }} />
                  <label>Title</label>
                </div>
                <input
                  type="text"
                  name="review_title"
                  value={review.review_title}
                  className="form-control"
                  placeholder="Your review title"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="row my-2">
              <div className="form-group">
                <div className="mb-2 d-flex align-items-center">
                  <ChatOutlinedIcon sx={{ marginRight: 0.5 }} />
                  <label>Review</label>
                </div>
                <textarea
                  name="review_description"
                  rows={10}
                  value={review.review_description}
                  className="form-control"
                  placeholder="Your game review"
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </section>
        <section id="ratings" className="game-solid-background">
          <div className="container p-4 mt-4">
            <div className="row">
              <h3 className="section-title">Game Ratings</h3>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-4 my-2">
                <div className="form-group">
                  <div className="mb-2 d-flex align-items-center">
                    <StarOutlineOutlinedIcon sx={{ marginRight: 0.5 }} />
                    <label>Overall Rating</label>
                  </div>
                  <Rating
                    name="overall-ratings"
                    emptyIcon={<StarBorder sx={{ color: ratingEmptyColor }} />}
                    max={10}
                    value={parseInt(review.game_rating)}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setReview({
                          ...review,
                          game_rating: newValue.toString(),
                        });
                      }
                    }}
                  />
                  <div className="mb-2 rating">
                    {getRatingLabel(review.game_rating)}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-4 my-2">
                <div className="form-group">
                  <div className="mb-2 d-flex align-items-center">
                    <DesktopWindowsOutlinedIcon sx={{ marginRight: 0.5 }} />
                    <label>Visual</label>
                  </div>
                  <Rating
                    name="visual-ratings"
                    emptyIcon={<StarBorder sx={{ color: ratingEmptyColor }} />}
                    max={10}
                    value={parseInt(review.rating_visual)}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setReview({
                          ...review,
                          rating_visual: newValue.toString(),
                        });
                      }
                    }}
                  />
                  <div className="mb-2 rating">
                    {getRatingLabel(review.rating_visual)}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-4 my-2">
                <div className="form-group">
                  <div className="mb-2 d-flex align-items-center">
                    <MusicNoteOutlinedIcon sx={{ marginRight: 0.5 }} />
                    <label>Audio</label>
                  </div>
                  <Rating
                    name="audio-ratings"
                    emptyIcon={<StarBorder sx={{ color: ratingEmptyColor }} />}
                    max={10}
                    value={parseInt(review.rating_audio)}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setReview({
                          ...review,
                          rating_audio: newValue.toString(),
                        });
                      }
                    }}
                  />
                  <div className="mb-2 rating">
                    {getRatingLabel(review.rating_audio)}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-4 my-2">
                <div className="form-group">
                  <div className="mb-2 d-flex align-items-center">
                    <GamepadOutlinedIcon sx={{ marginRight: 0.5 }} />
                    <label>Controls</label>
                  </div>
                  <Rating
                    name="control-ratings"
                    emptyIcon={<StarBorder sx={{ color: ratingEmptyColor }} />}
                    max={10}
                    value={parseInt(review.rating_control)}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setReview({
                          ...review,
                          rating_control: newValue.toString(),
                        });
                      }
                    }}
                  />
                  <div className="mb-2 rating">
                    {getRatingLabel(review.rating_control)}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-4 my-2">
                <div className="form-group">
                  <div className="mb-2 d-flex align-items-center">
                    <AutoStoriesOutlinedIcon sx={{ marginRight: 0.5 }} />
                    <label>Story</label>
                  </div>
                  <Rating
                    name="story-ratings"
                    emptyIcon={<StarBorder sx={{ color: ratingEmptyColor }} />}
                    max={10}
                    value={parseInt(review.rating_story)}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setReview({
                          ...review,
                          rating_story: newValue.toString(),
                        });
                      }
                    }}
                  />
                  <div className="mb-2 rating">
                    {getRatingLabel(review.rating_story)}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-4 my-2">
                <div className="form-group">
                  <div className="mb-2 d-flex align-items-center">
                    <SportsEsportsOutlinedIcon sx={{ marginRight: 0.5 }} />
                    <label>Gameplay</label>
                  </div>
                  <Rating
                    name="gameplay-ratings"
                    emptyIcon={<StarBorder sx={{ color: ratingEmptyColor }} />}
                    max={10}
                    value={parseInt(review.rating_gameplay)}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setReview({
                          ...review,
                          rating_gameplay: newValue.toString(),
                        });
                      }
                    }}
                  />
                  <div className="mb-2 rating">
                    {getRatingLabel(review.rating_gameplay)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <form>
          <div className="container mt-5">
            <div className="row align-items-center">
              {canEdit && (
                <Button
                  className="button-outline-custom mb-2"
                  variant="contained"
                  onClick={(e) => upsertData(e)}
                >
                  {action} REVIEW
                </Button>
              )}
              <Button
                className="button-outline-custom"
                onClick={(e) => closeForm(e)}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default GameReview;
