import { useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { EMPTY_GAME, IGame } from "../../types/Game";
import { IPlatformOption } from "../../types/Option";
import UserGame from "../../components/forms/UserGame";
import GameService from "../../services/GameService";
import useAlert from "../../hooks/userAlert";
import { setGameCollectionPageTitle, setGameImageBackground } from "../../utils/Display";
import { BackgroundImageOutletContext } from "../../components/layout/MainLayout";
import { EMPTY_REVIEW, IReview } from "../../types/Review";

const UpdateGamePage = () => {
  const { id } = useParams();

  const { setAlert } = useAlert();
  const fecthErrorMessage = "We had a problem while fetching the game details";
  const fecthReviewErrorMessage = "We had a problem while fetching the game review";
  const errorSelectOptions = "We had a problem while fetching select options data";

  const [game, setGame] = useState(EMPTY_GAME as IGame);
  const [review, setReview] = useState(EMPTY_REVIEW as IReview);
  const [isLoading, setIsLoading] = useState(false);
  const [platforms, setPlatforms] = useState([] as IPlatformOption[]);

  const { setBackgroundImage } = useOutletContext<BackgroundImageOutletContext>();

  useEffect(() => {
    fetchGame();
    fetchGameReview();
    fetchPlatforms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchGame = async () => {
    try {
      setIsLoading(true);
      const response = await GameService.getGameByID(id);
      const gameData = response.data;
      setGame(gameData);
      setGameCollectionPageTitle(gameData.game_title);
      setGameImageBackground(gameData, setBackgroundImage);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleResponseError(error, fecthErrorMessage);
    }
  };

  const fetchGameReview = async () => {
    try {
      const res = await GameService.getReviewByID(id);
      setReview(res.data);
    } catch (error) {
      handleResponseError(error, fecthReviewErrorMessage);
    }
  };

  const fetchPlatforms = async () => {
    try {
      const res = await GameService.getPlatforms();
      setPlatforms(res.data);
    } catch (error) {
      handleResponseError(error, errorSelectOptions);
    }
  };

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  return (
    <>
      {isLoading ? (
        <div className="spinner-grow" role="status" />
      ) : (
        <>
          <UserGame
            action="UPDATE"
            gameId={id}
            game={game}
            review={review}
            setGame={setGame}
            platforms={platforms}
          />
        </>
      )}
    </>
  );
};

export default UpdateGamePage;
